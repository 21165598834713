export const page = `
    title
    blueprint
    id
`

export const SEO = `
    seo_title
    seo_default_title_deactivated
    seo_hidden
    seo_description
    seo_image {
        ...Asset
    }
`

// HEROS

export const ComponentHeroHomepage = `
    title
    text
    scrolly_button_text
    type
    image {
        ...Asset
    }
    pages {
        overwrite_title
        title
        link_text
        page {
            url
            title
        }
    }
`

export const ComponentHeroContentpage = `
    title
    text
    type
    scrolly_button_text
    image {
        ...Asset
    }
`

export const ComponentHeroDetailpage = `
    title
    type
    link_text
    link
    text
    image {
        ...Asset
    }
`

export const ComponentHeroSimple = `
    bg_beige
    show_category
    title
    text
    type
`

export const ComponentHeroSuma = `
    type
    heading
    text
    cover_image {
        ...Asset
    }
`

export const ComponentHeroNews = `
    bg_beige
    show_category
    title
    text
    type
`

export const ComponentHeroContact = `
    title
    text
    type
    scroll_to_button_text
`

// COMPONENTS

export const Accordion = `
    heading
    button_open
    button_close
    type
    accordion {
      title
      content
    }
`

export const ComponentCards = `
    type
    title
    load_cards_automatically {
      value
    }
    cards {
        overwrite_title
        title
        link_text
        page {
            url
            slug
            ...PageHero
            ... on Entry_Pages_Page {
                page_categories {
                  slug
                  title
                }
            }
        }
    }
`

export const ComponentTeaserWithImage = `
    title
    link
    link_text
    text
    type
    inverted
    image {
        ...Asset
    }
    category: page_category {
      ... on Term_PageCategories_PageCategories {
          id
          title
          area {
            value
          }
      }
    }
`

export const ComponentTeaserSmall = `
    type
    title
    teasers {
        title
        text
        link
        link_text
        material_icon
        image {
          ...Asset
        }
    }
`

export const ComponentNewsletterSignUp = `
    type
    title_overwrite
    button_text_overwrite
    overwrite_success_title
    overwrite_success_text
    overwrite_groups {
      label
      value
    }
`

export const ComponentGeneralText = `
  content {
    ... on BardText {
      text
      type
    }
    ... on Set_Components_Content_CustomTextLinkButton {
      id
      type
      button_text
      button_link
    }
    ... on Set_Components_Content_CustomTextImageAndText {
      id
      type
      image {
        ...Asset
      }
      image_description
      text
      image_position {
        value
      }
      image_width
    }
  }
  type
`

export const SidebarComponentGeneralText = `
  content {
    ... on BardText {
      text
      type
    }
    ... on Set_SidebarComponents_Content_CustomTextLinkButton {
      id
      type
      button_text
      button_link
    }
    ... on Set_SidebarComponents_Content_CustomTextImageAndText {
      id
      type
      image {
        ...Asset
      }
      image_description
      text
      image_position {
        value
      }
      image_width
    }
  }
  type
`

export const ComponentWithSideMenuGeneralText = `
  content {
    ... on BardText {
      text
      type
    }
    ... on Set_Components_ComponentsWithSideMenu_Content_CustomTextLinkButton {
      id
      type
      button_text
      button_link
    }
    ... on Set_Components_ComponentsWithSideMenu_Content_CustomTextImageAndText {
      id
      type
      image {
        ...Asset
      }
      image_description
      text
      image_position {
        value
      }
      image_width
    }
  }
  type
`

export const ComponentContactCard = `
    title
    type
    person {
        ... on Entry_Team_TeamMember {
          title
          job_title
          email
          phone
          image {
            ...Asset
          }
        }
      }
    link_text
    link
`

export const ComponentIconCards = `
    heading
    type
    link
    link_text
    icon_cards {
        material_icon
        title
        text
    }
`

export const ComponentCustomerLogos = `
    title
    customer_logos {
        ... on Entry_CustomerLogos_CustomerLogos {
            id
            title
            link
            logo {
                ...Asset
            }
        }
    }
    type
`

export const ComponentImages = `
    type
    with_text
    enable_video_aspect_ratio
    zoomable
    image {
      ...Asset
    }
    caption
    images_with_text {
      text
      image {
        ...Asset
      }
    }
    image_size {
      value
    }
`

export const ComponentVideo = `
    title
    description
    caption
    video_url
    type
    overlay_image {
      ...Asset
    }
    video_file {
      ...Asset
    }

`

export const ComponentTestimonialSlider = `
    type
    load_automatically
    slides {
        ... on Entry_Testimonials_Testimonials {
            title
            job_title
            text
            image {
                ...Asset
            }
        }
    }
`

export const ComponentNewsSlider = `
    heading
    type
    load_automatically
    amount_news_articles
    show_background_color
    blog_posts {
        ...BlogPostCard
    }
    category {
      ... on Term_BlogCategories_BlogCategories {
        slug
      }
    }
`

export const ComponentSwisstainableDetailpageSlider = `
    heading
    type
    load_automatically
    amount_articles
    show_background_color
    swisstainable_detailpages {
        ...SwisstainableDetailpagePreview
    }
`

export const ComponentDownloads = `
    type
    heading
    files {
        name
        link_to_file
        file_size
        file_type
        open_in_tab
        file {
            size
            permalink
            extension
            id
            path
        }
    }
`
export const ComponentLinks = `
    type
    heading
    links {
      ... on Set_Components_Links_Links {
        id
        link
        title
      }
    }
`

export const SidebarComponentLinks = `
    type
    heading
    links {
      ... on Set_SidebarComponents_Links_Links {
        id
        link
        title
      }
    }
`

export const ComponentWithSideMenuLinks = `
    type
    heading
    links {
      ... on Set_Components_ComponentsWithSideMenu_Links_Links {
        id
        link
        title
      }
    }
`

export const ComponentCustomMap = `
    title
    type
    coordinates {
        lat
        long
    }
`

export const ComponentCallToAction = `
    title
    text
    button_title
    link
    link_text
    type
    image {
      ...Asset
    }
    category: page_category {
        ... on Term_PageCategories_PageCategories {
            id
            title
            area {
              value
            }
        }
    }
`

export const ComponentEventFilter = `
    title
    events_per_page
    filter_title_topic
    filter_title_location
    filter_title_event_time
    filter_title_date
    filter_text_show_all
    filter_text_hide
    uuid
    events {
        ...EventsPreview
    }
    event_categories {
        title
        slug
    }
    load_events_automatically {
        value
    }
    type
`

export const ComponentEvents = `
    type
    event_blocks {
        title
        amount_events
        link
        link_text
        events {
          ...EventsPreview
        }
        load_events_automatically {
          value
        }
        event_categories {
          slug
          title
        }
    }
`

export const ComponentListAndTables = `
  type
  tables {
    ... on Set_Components_Tables_Table {
      title
      collapsable
      collapsed
      content
      type
    }
  }
`

export const SidebarComponentListAndTables = `
  type
  tables {
    ... on Set_SidebarComponents_Tables_Table {
      title
      collapsable
      collapsed
      content
      type
    }
  }
`

export const ComponentWithSideMenuListAndTables = `
  type
  tables {
    ... on Set_Components_ComponentsWithSideMenu_Tables_Table {
      title
      collapsable
      collapsed
      content
      type
    }
  }
`

export const ComponentCustomForm = `
  title
  text
  subtitle
  type
  privacy_policy_checkbox_label
  success_title
  success_text
  send_button_text
  form {
    handle
    title
    rules
    honeypot
    fields {
      config
      display
      instructions
      handle
      type
    }
  }
`

export const ComponentCustomFormEventManager = `
  title
  text
  subtitle
  type
  privacy_policy_checkbox_label
  success_title
  success_text
  send_button_text
  form {
    handle
    title
    rules
    honeypot
    fields {
      config
      display
      instructions
      handle
      type
    }
  }
  event {
    ... on Entry_EventManager_Event {
      id
      days {
        ... on Entry_EventManager_Day {
          id
          title
          time_slots {
            ... on Entry_EventManager_TimeSlot {
              id
              title
              start
              end
              topics {
                ... on Entry_EventManager_Topic {
                  id
                  title
                  subtitle
                  amount_of_signups
                  max_participants
                }
              }
            }
          }
        }
        ... on Entry_EventManager_DayWithContingent {
          id
          blueprint
          title
          text
          amount_of_signups
          amount_of_free_seats
          max_participants
        }
      }
    }
  }
`

export const ComponentTwitterFeed = `
  type
  title
`

export const ComponentBlogOverview = `
  type
  heading
  category {
    ... on Term_BlogCategories_BlogCategories {
      slug
    }
  }
  categories_filter_title
`

export const ComponentSwisstainableHub = `
  type
  heading
  text
  category {
    ... on Term_SwisstainableHubCategories_SwisstainableHubCategory {
      slug
    }
  }
  categories_filter_title
  topic_areas_filter_title
`

export const ComponentSwisstainableIllustration = `
  type
  swistainable_illu_title: heading
`

export const ComponentConsultantsOverview = `
  type
  title
`

export const ComponentMemberOffersOverview = `
  type
  member_offers_title
`

export const ComponentSumaOffersOverview = `
  type
  title
  category_filter {
    slug
  }
`

export const ComponentProductOverview = `
  type
  title
  remark
  product_categories {
    title
    products {
      ... on Entry_Products_Products {
        id
        title
        text
        can_be_ordered
        image {
          ...Asset
        }
        downloads {
          link
          language {
            value
            label
          }
          file {
            ...Asset
          }
        }
      }
    }
  }
  hide_form
  terms_accepted
  success_title
  success_text
`

export const ComponentIframeSwisstainable = `
  type
`

export const ComponentCustomIframe = `
  type
  heading
  url
`

export const ComponentEmbedCode = `
  type
  code {
    code
  }
`

export const ComponentTiles = `
  id
  type
  tiles {
    id
    number
    text
    prefix
  }
  title
  subtitle
`

export const ComponentFlipbookIntegration = `
  id
  type
  heading
  embed_code
`

export const ComponentReplicatorWithSideMenu = `
  id
  type
  components_with_side_menu {
    ... on Set_Components_ComponentsWithSideMenu_Accordion {
      ${Accordion}
    }
    ... on Set_Components_ComponentsWithSideMenu_TeaserWithImage {
      ${ComponentTeaserWithImage}
    }
    ... on Set_Components_ComponentsWithSideMenu_TeaserSmall {
      ${ComponentTeaserSmall}
    }
    ... on Set_Components_ComponentsWithSideMenu_TestimonialSlider {
      ${ComponentTestimonialSlider}
    }
    ... on Set_Components_ComponentsWithSideMenu_Images {
      ${ComponentImages}
    }
    ... on Set_Components_ComponentsWithSideMenu_IconCards {
      ${ComponentIconCards}
    }
    ... on Set_Components_ComponentsWithSideMenu_ContactCard {
      ${ComponentContactCard}
    }
    ... on Set_Components_ComponentsWithSideMenu_CustomerLogos {
      ${ComponentCustomerLogos}
    }
    ... on Set_Components_ComponentsWithSideMenu_Cards {
      ${ComponentCards}
    }
    ... on Set_Components_ComponentsWithSideMenu_Downloads {
      ${ComponentDownloads}
    }
    ... on Set_Components_ComponentsWithSideMenu_Links {
      ${ComponentWithSideMenuLinks}
    }
    ... on Set_Components_ComponentsWithSideMenu_GeneralText {
      ${ComponentWithSideMenuGeneralText}
    }
    ... on Set_Components_ComponentsWithSideMenu_Tiles {
      ${ComponentTiles}
    }
    ... on Set_Components_ComponentsWithSideMenu_ListAndTables {
      ${ComponentWithSideMenuListAndTables}
    }
  }
`

export const ReplicatorHeros = `
    heros {
        ... on Set_Heros_HeroHomepage {
            ${ComponentHeroHomepage}
        }
        ... on Set_Heros_HeroContact {
            ${ComponentHeroContact}
        }
        ... on Set_Heros_HeroContentpage {
            ${ComponentHeroContentpage}
        }
        ... on Set_Heros_HeroDetailpage {
            ${ComponentHeroDetailpage}
        }
        ... on Set_Heros_HeroSimple {
            ${ComponentHeroSimple}
        }
        ... on Set_Heros_HeroNews {
            ${ComponentHeroNews}
        }
        ... on Set_Heros_HeroSuma {
            ${ComponentHeroSuma}
        }
    }
`

export const ReplicatorComponents = `
    components {
        ... on Set_Components_Accordion {
            ${Accordion}
        }
        ... on Set_Components_TeaserWithImage {
            ${ComponentTeaserWithImage}
        }
        ... on Set_Components_TeaserSmall {
            ${ComponentTeaserSmall}
        }
        ... on Set_Components_NewsletterSignUp {
            ${ComponentNewsletterSignUp}
        }
        ... on Set_Components_TestimonialSlider {
            ${ComponentTestimonialSlider}
        }
        ... on Set_Components_GeneralText {
            ${ComponentGeneralText}
        }
        ... on Set_Components_Images {
            ${ComponentImages}
        }
        ... on Set_Components_IconCards {
            ${ComponentIconCards}
        }
        ... on Set_Components_ContactCard {
            ${ComponentContactCard}
        }
        ... on Set_Components_CustomerLogos {
            ${ComponentCustomerLogos}
        }
        ... on Set_Components_NewsSlider {
            ${ComponentNewsSlider}
        }
        ... on Set_Components_Cards {
            ${ComponentCards}
        }
        ... on Set_Components_Downloads {
            ${ComponentDownloads}
        }
        ... on Set_Components_Links {
            ${ComponentLinks}
        }
        ... on Set_Components_Video {
            ${ComponentVideo}
        }
        ... on Set_Components_CustomMap {
            ${ComponentCustomMap}
        }
        ... on Set_Components_CallToAction {
            ${ComponentCallToAction}
        }
        ... on Set_Components_EventFilter {
            ${ComponentEventFilter}
        }
        ... on Set_Components_Events {
          ${ComponentEvents}
        }
        ... on Set_Components_ListAndTables {
          ${ComponentListAndTables}
        }
        ... on Set_Components_CustomForm {
          ${ComponentCustomForm}
        }
        ... on Set_Components_CustomFormEventManager {
          ${ComponentCustomFormEventManager}
        }
        ... on Set_Components_TwitterFeed {
          ${ComponentTwitterFeed}
        }
        ... on Set_Components_BlogOverview {
          ${ComponentBlogOverview}
        }
        ... on Set_Components_SwisstainableHub {
          ${ComponentSwisstainableHub}
        }
        ... on Set_Components_SwisstainableIllustration {
          ${ComponentSwisstainableIllustration}
        }
        ... on Set_Components_ConsultantsOverview {
          ${ComponentConsultantsOverview}
        }
        ... on Set_Components_MemberOffersOverview {
          ${ComponentMemberOffersOverview}
        }
        ... on Set_Components_ProductOverview {
          ${ComponentProductOverview}
        }
        ... on Set_Components_SumaOffersOverview {
          ${ComponentSumaOffersOverview}
        }
        ... on Set_Components_IframeSwisstainable {
          ${ComponentIframeSwisstainable}
        }
        ... on Set_Components_CustomIframe {
          ${ComponentCustomIframe}
        }
        ... on Set_Components_Tiles {
          ${ComponentTiles}
        }
        ... on Set_Components_ReplicatorWithSideMenu {
          ${ComponentReplicatorWithSideMenu}
        }
        ... on Set_Components_FlipbookIntegration {
          ${ComponentFlipbookIntegration}
        }
        ... on Set_Components_EmbedCode {
          ${ComponentEmbedCode}
        }
    }
`

export const ReplicatorSidebarComponents = `
    sidebar_components {
        ... on Set_SidebarComponents_Accordion {
            ${Accordion}
          }
          ... on Set_SidebarComponents_TeaserWithImage {
            ${ComponentTeaserWithImage}
          }
          ... on Set_SidebarComponents_TeaserSmall {
            ${ComponentTeaserSmall}
          }
          ... on Set_SidebarComponents_NewsletterSignUp {
            ${ComponentNewsletterSignUp}
          }
          ... on Set_SidebarComponents_TestimonialSlider {
            ${ComponentTestimonialSlider}
          }
          ... on Set_SidebarComponents_GeneralText {
            ${SidebarComponentGeneralText}
          }
          ... on Set_SidebarComponents_Images {
            ${ComponentImages}
          }
          ... on Set_SidebarComponents_IconCards {
            ${ComponentIconCards}
          }
          ... on Set_SidebarComponents_ContactCard {
            ${ComponentContactCard}
          }
          ... on Set_SidebarComponents_CustomerLogos {
            ${ComponentCustomerLogos}
          }
          ... on Set_SidebarComponents_NewsSlider {
            ${ComponentNewsSlider}
          }
          ... on Set_SidebarComponents_Cards {
            ${ComponentCards}
          }
          ... on Set_SidebarComponents_Downloads {
            ${ComponentDownloads}
          }
          ... on Set_SidebarComponents_Links {
            ${SidebarComponentLinks}
          }
          ... on Set_SidebarComponents_Video {
            ${ComponentVideo}
          }
          ... on Set_SidebarComponents_CustomMap {
            ${ComponentCustomMap}
          }
          ... on Set_SidebarComponents_CallToAction {
            ${ComponentCallToAction}
          }
          ... on Set_SidebarComponents_EventFilter {
            ${ComponentEventFilter}
          }
          ... on Set_SidebarComponents_Events {
            ${ComponentEvents}
          }
          ... on Set_SidebarComponents_ListAndTables {
            ${SidebarComponentListAndTables}
          }
          ... on Set_SidebarComponents_CustomForm {
            ${ComponentCustomForm}
          }
          ... on Set_SidebarComponents_CustomFormEventManager {
            ${ComponentCustomFormEventManager}
          }
          ... on Set_SidebarComponents_TwitterFeed {
            ${ComponentTwitterFeed}
          }
          ... on Set_SidebarComponents_BlogOverview {
            ${ComponentBlogOverview}
          }
          ... on Set_SidebarComponents_SwisstainableHub {
            ${ComponentSwisstainableHub}
          }
          ... on Set_SidebarComponents_SwisstainableIllustration {
            ${ComponentSwisstainableIllustration}
          }
          ... on Set_SidebarComponents_ConsultantsOverview {
            ${ComponentConsultantsOverview}
          }
          ... on Set_SidebarComponents_MemberOffersOverview {
            ${ComponentMemberOffersOverview}
          }
          ... on Set_SidebarComponents_ProductOverview {
            ${ComponentProductOverview}
          }
          ... on Set_SidebarComponents_SumaOffersOverview {
            ${ComponentSumaOffersOverview}
          }
          ... on Set_SidebarComponents_IframeSwisstainable {
            ${ComponentIframeSwisstainable}
          }
          ... on Set_SidebarComponents_CustomIframe {
            ${ComponentCustomIframe}
          }
          ... on Set_SidebarComponents_Tiles {
            ${ComponentTiles}
          }
          ... on Set_SidebarComponents_FlipbookIntegration {
            ${ComponentFlipbookIntegration}
          }
          ... on Set_SidebarComponents_EmbedCode {
            ${ComponentEmbedCode}
          }
    }
`
