import { gql } from 'nuxt-graphql-request'
import {
  page,
  SEO,
  ReplicatorHeros,
  ReplicatorComponents,
  ReplicatorSidebarComponents,
  ComponentNewsletterSignUp,
  ComponentNewsSlider,
  ComponentSwisstainableDetailpageSlider,
} from './fragments-component'

// BLUEPRINT "Pages"
export const PagesBlueprint = gql`
fragment PagesBlueprint on Entry_Pages_Page {
    ${page}

    chat_active
    embed_code

    page_categories {
      ... on Term_PageCategories_PageCategories {
        id
        title
        area {
          value
        }
      }
    }

    ${SEO}
    ${ReplicatorHeros}
    ${ReplicatorComponents}
    ${ReplicatorSidebarComponents}

    entry_to_get_additional_navigation {
      url
    }
    parent {
      ... on Entry_Pages_Page {
        entry_to_get_additional_navigation {
          url
        }
      }
      parent {
        ... on Entry_Pages_Page {
          entry_to_get_additional_navigation {
            url
          }
        }
      }
    }
  }
`

// BLUEPRINT "Team"
export const TeamBlueprint = gql`
fragment TeamBlueprint on Entry_Pages_Team {
    ${page}

    chat_active
    embed_code

    show_chairmans
    team_page_categories {
      ... on Term_PageCategories_PageCategories {
        id
        title
        area {
          value
        }
      }
    }

    ${SEO}
    ${ReplicatorHeros}
    ${ReplicatorComponents}
  }
`

// BLUEPRINT "Team Member"
export const TeamMemberBlueprint = gql`
  fragment TeamMemberBlueprint on Entry_Team_TeamMember {
    ${SEO}

    title
    job_title
    email
    phone
    is_chairman
    hidden
    image {
      ...Asset
    }
  }
`

// BLUEPRINT "Blog Article"
export const BlogPostBlueprint = gql`
  fragment BlogPostBlueprint on EntryInterface {
    ... on Entry_BlogPosts_BlogPost {
      ${SEO}

      chat_active
      embed_code
      id
      uri
      is_big
      text: intro
      title
      slug
      date
      image {
        ...Asset
      }
      caption
      blog_categories {
        ... on Term_BlogCategories_BlogCategories {
          title
          slug
        }
      }
      authors {
        ... on Entry_Team_TeamMember {
          title
          job_title
          phone
          email
        }
      }
      blog_post_content {
        ... on BardText {
          text
          type
        }
        ... on Set_BlogPostContent_DefaultImage {
          type
          caption
          is_full_width
          image {
            ...Asset
          }
        }
        ... on Set_BlogPostContent_Video {
          title
          description
          caption
          video_url
          type
          overlay_image {
            ...Asset
          }
          video_file {
            ...Asset
          }
        }
        ... on Set_BlogPostContent_EmbedCode {
          type
          code {
            code
          }
        }
        ... on Set_BlogPostContent_Accordion {
          heading
          button_open
          button_close
          type
          accordion {
            title
            content
          }
        }
      }
      blog_post_components {
          ... on Set_BlogPostComponents_NewsletterSignUp {
            ${ComponentNewsletterSignUp}
          }
          ... on Set_BlogPostComponents_NewsSlider {
            ${ComponentNewsSlider}
          }
      }
    }
  }
`

// BLUEPRINT "Events"
export const EventsBlueprint = gql`
  fragment EventsBlueprint on EntryInterface {
    ... on Entry_Events_EventsDetail {
      ${SEO}

      chat_active
      embed_code
      id
      slug
      url
      title
      text: intro
      image {
        ...Asset
      }
      from
      to
      link_external_content
      content_source {
        value
      }
      event_locations {
        title
        slug
      }
      event_categories {
        title
        slug
      }
      event_time {
        title
        slug
      }
      authors {
        ... on Entry_Team_TeamMember {
          title
          job_title
          phone
          email
        }
      }
      content: event_detail_content {
        ... on Set_EventDetailContent_DefaultImage {
          caption
          is_full_width
          type
          image {
            ...Asset
          }
        }
        ... on BardText {
          text
          type
        }
      }
      event_components {
          ... on Set_EventComponents_NewsletterSignUp {
            ${ComponentNewsletterSignUp}
          }
          ... on Set_EventComponents_NewsSlider {
            ${ComponentNewsSlider}
          }
      }
    }
  }
`

// BLUEPRINT "Swisstainable Hub Detailpage"
export const SwisstainableHubDetailpageBlueprint = gql`
  fragment SwisstainableHubDetailpageBlueprint on EntryInterface {
    ... on Entry_SwisstainableHub_SwisstainableHubDetailpage {
      ${SEO}

      chat_active
      embed_code
      id
      uri
      text: intro
      title
      slug
      date
      image {
        ...Asset
      }
      caption

      page_categories {
        ... on Term_PageCategories_PageCategories {
          id
          title
          area {
            value
          }
        }
      }
      swisstainable_hub_categories {
        ... on Term_SwisstainableHubCategories_SwisstainableHubCategory {
          id
          title
          slug
        }
      }
      swisstainable_hub_topics {
        ... on Term_SwisstainableHubTopics_SwisstainableHubTopic {
          id
          title
          slug
          topic_area {
              id
              slug
              title
          }
        }
      }
      authors {
        ... on Entry_Team_TeamMember {
          title
          job_title
          phone
          email
        }
      }
      authors_external {
        ... on Set_AuthorsExternal_Author {
          id
          email
          job_title
          company
          company_link
          phone
          title
        }
      }
      swisstainable_detailpage_content {
        ... on BardText {
          text
          type
        }
        ... on Set_SwisstainableDetailpageContent_DefaultImage {
          type
          caption
          is_full_width
          image {
            ...Asset
          }
        }
        ... on Set_SwisstainableDetailpageContent_Video {
          title
          description
          caption
          video_url
          type
          overlay_image {
            ...Asset
          }
          video_file {
            ...Asset
          }
        }
        ... on Set_SwisstainableDetailpageContent_EmbedCode {
          type
          code {
            code
          }
        }
        ... on Set_SwisstainableDetailpageContent_Accordion {
          heading
          button_open
          button_close
          type
          accordion {
            title
            content
          }
        }
      }
      swisstainable_detailpage_components {
          ... on Set_SwisstainableDetailpageComponents_NewsletterSignUp {
            ${ComponentNewsletterSignUp}
          }
          ... on Set_SwisstainableDetailpageComponents_SwisstainableDetailpageSlider {
            ${ComponentSwisstainableDetailpageSlider}
          }
      }
    }
  }
`

// BLUEPRINT "Offer"
export const OfferBlueprint = gql`
  fragment OfferBlueprint on EntryInterface {
    ... on Entry_Offers_Offer {
      ${SEO}

      chat_active
      embed_code

      slug
      url
      title
      country {
        label
        value
      }
      intro
      link_text
      link

      firma
      strasse_nr
      plz_ort
      contact_email
      telefon

      website

      body_title
      body
      youtube_link

      offers_categories {
        title
        slug
      }
      cover_image {
        ...Asset
      }
      logo {
        ...Asset
      }
      inhaltsbilder {
        ...Asset
      }

      page_categories {
        ... on Term_PageCategories_PageCategories {
          id
          title
          area {
            value
          }
        }
      }
    }
  }
`

// BLUEPRINT "Partner"
export const PartnerBlueprint = gql`
  fragment PartnerBlueprint on EntryInterface {
    ... on Entry_Partners_Partner {
      ${SEO}

      chat_active
      embed_code

      slug
      url
      title
      opt_country: country {
        label
        value
      }
      intro
      link_text
      link

      firma
      strasse_nr
      plz_ort
      contact_email
      telefon

      website

      body_title
      partner_body: body
      youtube_link

      opt_offers_categories: offers_categories {
        title
        slug
      }
      cover_image {
        ...Asset
      }
      logo {
        ...Asset
      }
      inhaltsbilder {
        ...Asset
      }

      opt_page_categories: page_categories {
        ... on Term_PageCategories_PageCategories {
          id
          title
          area {
            value
          }
        }
      }

      cta {
        title
        text
        button_title
        link
        link_text
        image {
          ...Asset
        }
        page_category {
          ... on Term_PageCategories_PageCategories {
            id
            title
            area {
              value
            }
          }
        }
      }
    }
  }
`
